<template>
	<div id="forgetpwd">
		<div class="forgetpwd_in">
			<!-- <div class="resetpwd_top">
				<img class="pclogo" src="~/assets/images/nav/logo.png" alt="找不到资源" @click="toindex">
				<img class="phonelogo" src="~/assets/images/images_phone_show/logo_mini.png" alt="找不到资源" @click="toindex">
				<span>|</span>
				<h2>找回密码</h2>
			</div> -->
			<nav-tab-part1 pagetitle="找回密码">
				<template v-slot:topRightText><span @click='login'>返回登录</span></template>
			</nav-tab-part1>
			<div class="progress_bar">
				<el-steps :active="step_active">
					<el-step title="步骤 1" icon="el-icon-menu"></el-step>
					<el-step title="步骤 2" icon="el-icon-mobile-phone"></el-step>
					<el-step title="步骤 3" icon="el-icon-edit-outline"></el-step>
					<el-step title="步骤 3" icon="el-icon-success"></el-step>
				</el-steps>
			</div>
			<div class="stepBox">
				<div v-if="step_active==1" class="stepone">
					<h3>请选择找回方式：</h3>
					<div class="findstyle">
						<el-button @click="style_find=0,step_active+=1">手机验证码找回</el-button>
					</div>
					<!-- <el-button :plain="true" @click="tip">邮箱找回</el-button> -->
				</div>
				<div v-if="step_active==2" class="steptwo">
					<!-- <h3 v-if="style_find==0">请输入手机号：</h3> -->
					<!-- <h3 v-if="style_find==1">请输入邮箱号：</h3> -->
					<!-- <el-input v-model="inputvalue"></el-input><div>获取验证码</div> -->
					<!-- <el-input v-model="back_code"></el-input> -->
					<el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
						<el-form-item label="手机号码" prop="phone">
							<el-input type="tel" maxlength=11 v-model.number="ruleForm.phone"></el-input>
						</el-form-item>
						<el-form-item label="短信验证" prop="code">
							<el-input type="text" maxlength=6 v-model="ruleForm.code" style="width: 198px;position: relative;"></el-input>
							<p class="getcode" v-show="!iscode" style="position: absolute;right: 0;top: 0;" @click="getcode">获取验证码</p>
							<p class="getcodes" v-show="iscode" style="position: absolute;right: 0;top: 0;">{{ secode }}秒后重新获取</p>
						</el-form-item>
						<!-- <el-form-item label="输入新密码" prop="newpwd">
							<el-input type="text" v-model="ruleForm.newpwd"></el-input>
						</el-form-item> -->
					</el-form>
					
					<div class="operationBtn">
						<el-button type="nomarl" @click="step_active-=1">上一步</el-button>
						<el-button type="primary" @click="step_active+=1">下一步</el-button>
					</div>
				</div>
				<div v-if="step_active==3" class="stepthree">
					<h3>输入新密码</h3>
					<el-input show-password v-model="ruleForm.newpwd"></el-input>
					<div class="compelete">
						<el-button type="sucess" @click="checkpwd">完成</el-button>
					</div>
				</div>
				<div v-if="step_active==4" class="stepfour">
					 <div class="successimg">
						<img src="~/assets/images/login/success.png" alt="404" />
						</div>
						<div class="registsuccess_tipinfo">
						<p>修改成功</p>
						</div>
						<div class="loginbtn">
						<el-button @click="login">立即登录</el-button>
						</div>
					</div>
			</div>
		</div>
	</div>
</template>

<script>
import navTabPart1 from '@/components/common/nav/navTab_part1.vue'
import NavTab_part1 from '../../components/common/nav/navTab_part1.vue';
export default{
	name:'ForgetPwd',
	data(){
		var validatePhone = (rule,value,callback) => {
			if (value === '') {
				callback(new Error('手机号不可为空'));
			} else {
				if (value !== '') { 
					var reg=/^1[3456789]\d{9}$/;
				if(!reg.test(value)){
					callback(new Error('请输入有效的手机号码'));
				}
			}
				callback();
			}
		};
		var validateCode = (rule,value,callback) => {
			if (value === '') {
				callback(new Error('验证码不可为空'));
			} else{
				callback();
			}
		};
		var validateNewowd = (rule,value,callback) => {
			if (value === '') {
				callback(new Error('输入密码不可为空'));
			} else{
				callback();
			}
			
		}
		return{
			iscode:false,
			secode:60,
			codetime: null,
			step_active:1, //步骤索引
			style_find:0,//0是手机验证码找回,  暂无（1是邮箱找回)
			// inputvalue:'',//输入框输入内容
			// back_code:'',//填写返回验证码
			// newpwd:'',//新密码
			ruleForm: {
				phone:'',
				code:'',
				newpwd: ''
			},
			rules:{
				phone:[
					{ validator: validatePhone, trigger: 'blur' }
				],
				code:[
					{ valdator: validateCode, trigger: 'blur' }
				],
				newpwd: [
					{ validator: validateNewowd, trigger: 'blur' }
				]
			}
		}
	},
	components:{
		navTabPart1
	},
	created() {
		// document.title = '忘记密码-找回密码'
	},
	methods:{
		//获取验证码
		getcode() {     
		this.iscode = true;
		this.secode = 60;
		this.codetime = setInterval(() => {
			this.secode--;
			if (this.secode <= 0) {
			this.iscode = false;
			clearInterval(this.codetime);
			}
		}, 1000);
		},
		//修改密码
		checkpwd(){
			console.log(this.inputvalue)
			let requestParams = {
				Version:'',
				MobileNo:this.inputvalue,
				PassWord:this.newpwd,
				Code:'123',
				Zone:''
			}
			this.$axios('/API/ResetPWD',requestParams).then( res => {
				console.log(res)
			})
			this.step_active+=1
		},
		toindex(){
			this.$router.push("/home");
		},
		//跳转至登录页面
		login() {
		this.$router.replace("/login");
		}
		
	}
}
</script>

<style lang="scss">
	@media(min-width: 1201px){
		@import '@/pages/login/ForgetPwd.scss';
	}
	@media(max-width: 1200px){
		@import '@/pages/login/ForgetPwd_phone.scss';
	}
</style>
